<template>
	<!-- 打印机设置 -->
	<div class="set-print el-content">
		<a-button v-has="{action:'wxapp_outlet_print_add'}" type="primary" @click="show.add = true">
			<i class="ri-add-line ri-lgri-top"></i>
			添加打印机
		</a-button>
		<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'门店id',dataIndex:'outlet_id'},
			{title:'打印机编号',dataIndex:'print_sn'},
			{title:'飞鹅云UKEY',dataIndex:'print_key'},
			{title:'飞鹅云账号',dataIndex:'print_user'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-more-fill" @click="showAdd(record)" v-has="{action:'wxapp_outlet_print_add'}"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_outlet_print_del'}" @click="delPrints(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getPrintList(info.page,e)}"
				@change="(e)=>{getPrintList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.add" title="添加编辑" @ok="saveOutLet" @cancel="showAdd(false)" width="600px">
			<a-form :label-col="{ span: 4}" :wrapper-col="{ span: 16 }">
				<a-form-item label="请选择门店">
					<a-select
						show-search
						v-model:value="form.outlet_id"
						:defaultActiveFirstOption="false"
						placeholder="请输入门店名称进行搜索查找"
						:show-arrow="false"
						:filter-option="false"
						:not-found-content="null"
						@search="getOutlet"
					>
						<a-select-option value="" >请输入门店名称进行搜索查找</a-select-option>
						<a-select-option v-for="(item, index) in outlet" :key="index" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>

				</a-form-item>
				<a-form-item label="飞鹅云UKEY">
					<a-input v-model:value="form.print_key"></a-input>
				</a-form-item>
				<a-form-item label="飞鹅云账号">
					<a-input v-model:value="form.print_user"></a-input>
				</a-form-item>
				<a-form-item label="打印机编号">
					<a-input v-model:value="form.print_sn"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import { reactive, toRefs } from 'vue';
import outletModel from '@/api/addons/outlet';
export default{
	components:{
		comShopSearchSelect
	},
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			show:{
				add:false,		//是否显示添加/编辑弹框
			},
			form:{
				id:0,
				outlet_id:'',
				print_sn:'',
				print_key:'',
				print_user:''
			},
			outlet:[],		//门店列表
		})
		getPrintList(1,state.info.limit)
		function getPrintList(page,limit){
			outletModel.getPrint(page,limit,res=>state.info = {limit,...res})
		}

		function getOutlet(e){
			outletModel.getOutletList(1,999,{name:e},res=>state.outlet = res.list)
		}
		
		function getOutletDetail(id){
			outletModel.getOutletDetail(id,null,res=>state.outlet.push(res))
		}

		function showAdd(row){
			state.show.add = row ? true : false
			state.form.outlet_id = row ? row.outlet_id : '',
			state.form.print_sn = row ? row.print_sn : '',
			state.form.print_key = row ? row.print_key : '',
			state.form.print_user = row ? row.print_user : ''
			state.form.id = row ? row.id : 0
			if(!row ){
				state.outlet = []
			}else{
				if( row.outlet_id ) getOutletDetail(row.outlet_id)
			}
		}

		const saveOutLet = ()=>outletModel.addOrEditPrint(state.form,()=>{
			state.show.add = false
			getPrintList(state.info.page,state.info.limit)
		})

		const delPrints = (id)=>outletModel.deletePrint(id,()=>getPrintList(state.info.page,state.info.limit))

		return{
			...toRefs(state),
			getPrintList,
			getOutlet,
			getOutletDetail,
			saveOutLet,
			delPrints,
			showAdd
		}
	}
}
</script>

<style lang="scss">
</style>
